import React, { FC } from 'react';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ITextSection } from './model';

import './TextSection.scss';

const TextSection: FC<ITextSection> = ({
  text,
  colWidth = { md: { span: 9, offset: 1 } },
  themeClass = 'references-theme',
}) => (
  <Container
    fluid
    className={classnames('text-section', {
      [`text-section--${themeClass}`]: themeClass,
    })}
  >
    <Row>
      <Col {...colWidth}>
        <DangerouslySetInnerHtml html={text} />
      </Col>
    </Row>
  </Container>
);

export default TextSection;
