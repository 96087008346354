import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import Button from 'components/common/Button';
import UniversalImage from 'components/common/UniversalImage/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IPodcastProps } from './model';

import './Podcast.scss';

const Podcast: FC<IPodcastProps> = ({
  title,
  description,
  cta: [{ url, name, target }],
  ctaAriaLabel,
  image,
  alt,
  sectionBackground,
}) => (
  <section
    className={classnames('podcast', {
      [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
        ?.properties?.colorPicker?.label,
    })}
    data-testid="podcast"
  >
    <div className="podcast__container">
      <div className="podcast__content">
        <div className="podcast__image">
          <UniversalImage img={image} imageAlt={alt} />
        </div>
        <div className="podcast__text">
          <DangerouslySetInnerHtml className="podcast__title" html={title} />
          <DangerouslySetInnerHtml className="podcast__description" html={description} />
          <Button link={url} target={target} ariaLabel={ctaAriaLabel} variant="podcast">
            {name}
          </Button>
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentPodcast on IPodcast {
    title
    description
    cta {
      name
      url
      target
    }
    ctaAriaLabel
    image {
      ...FragmentFluidSmallImage
    }
    alt
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;

export default Podcast;
