import React, { FC } from 'react';
import { Link, graphql } from 'gatsby';
import classnames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IReports } from './model';

import './Reports.scss';

const Reports: FC<IReports> = ({
  title,
  description,
  reports,
  sectionAnchor,
  sectionBackground,
}) => (
  <section
    className={classnames('reports', {
      [`${sectionBackground?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBackground?.[0]
        ?.properties?.colorPicker?.label,
    })}
    id={sectionAnchor}
  >
    <div className="reports__container">
      <div className="reports__header">
        <h2 className="reports__header-title">{title}</h2>
        <DangerouslySetInnerHtml className="reports__header-description" html={description} />
      </div>
      <ul className="reports__list">
        {reports?.map(
          ({
            properties: {
              title: reportTitle,
              description: reportDescription,
              cta,
              ctaAria,
              image,
              imageAlt,
            },
          }) => (
            <li className="reports__list-item" key={reportTitle}>
              <Link to={cta?.[0]?.url} aria-label={ctaAria} key={reportTitle}>
                <div className="reports__item-image">
                  <UniversalImage img={image} imageAlt={imageAlt} />
                </div>

                <div className="reports__item-text">
                  <p className="reports__item-title">{reportTitle}</p>
                  <DangerouslySetInnerHtml
                    html={reportDescription}
                    className="reports__item-description"
                  />
                </div>
              </Link>
            </li>
          )
        )}
      </ul>
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentsReports on IReports {
    title
    description
    sectionAnchor
    reports {
      properties {
        title
        description
        cta {
          name
          url
        }
        ctaAria
        image {
          ...FragmentFluidMiddleImage
        }
        imageAlt
      }
    }
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;

export default Reports;
