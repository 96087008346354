import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ITextWithImage } from './model';
import './TextWithImage.scss';

const TextWithImage: FC<ITextWithImage> = ({ content: [{ properties }], sectionAnchor }) => {
  return (
    <Container fluid id={sectionAnchor}>
      <Row>
        <Col lg={{ span: 10, offset: 2 }} className="text-with-image__col">
          <div className="text-with-image">
            <div className="text-with-image__text">
              <DangerouslySetInnerHtml html={properties?.text} />
            </div>
            {properties?.image ? (
              <div className="text-with-image__image">
                <UniversalImage img={properties.image} imageAlt={properties?.imageAlt} />
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export const query = graphql`
  fragment FragmentTextWithImage on ITextWithImage {
    sectionAnchor
    content {
      properties {
        text
        image {
          ...FragmentFluidMiddleImage
        }
        imageAlt
      }
    }
  }
`;

export default TextWithImage;
