import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ITimelineProps } from './model';

import './Timeline.scss';

const Timeline: FC<ITimelineProps> = ({
  title,
  subtitle,
  description,
  timelineItems,
  sectionAnchor,
  sectionBackground: [
    {
      properties: { colorPicker },
    },
  ],
  colWidth = { lg: 12 },
}) => (
  <section
    className={classnames('timeline', {
      [`${colorPicker?.label}-default-bg`]: colorPicker?.label,
    })}
    id={sectionAnchor}
  >
    <Container fluid>
      <Row>
        <Col {...colWidth}>
          <h2 className="timeline__title">{title}</h2>
          <p className="timeline__subtitle">{subtitle}</p>
          <DangerouslySetInnerHtml html={description} className="timeline__description" />

          <ul className="timeline__list">
            {timelineItems.map(
              ({ properties: { title: itemTitle, description: itemDescription } }) => (
                <li className="timeline__list-item" key={itemTitle}>
                  <p className="timeline__list-item-title">{itemTitle}</p>
                  <DangerouslySetInnerHtml
                    className="timeline__list-item-description"
                    html={itemDescription}
                  />
                </li>
              )
            )}
          </ul>
        </Col>
      </Row>
    </Container>
  </section>
);

export const query = graphql`
  fragment FragmentsTimeline on ITimeline {
    title
    subtitle
    description
    timelineItems {
      properties {
        title
        description
      }
    }
    sectionAnchor
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;

export default Timeline;
