import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';

import { IPledgeProps } from './model';

import './Pledge.scss';

const Pledge: FC<IPledgeProps> = ({
  title,
  description,
  items,
  sectionAnchor,
  sectionBackground: [
    {
      properties: { colorPicker },
    },
  ],
  sectionTheme,
}) => (
  <section
    className={classnames('pledge', {
      [`${colorPicker?.label}-default-bg`]: colorPicker?.label,
      [`${sectionTheme}`]: sectionTheme,
    })}
    id={sectionAnchor}
  >
    <div className="pledge__container">
      <div className="pledge__header">
        <h2 className="pledge__title">{title}</h2>
        <DangerouslySetInnerHtml html={description} className="pledge__description" />
      </div>

      <ul className="pledge__list">
        {items.map(
          ({ properties: { title: itemTitle, description: itemDescription, image, imageAlt } }) => (
            <li className="pledge__list-item" key={itemTitle}>
              <div className="pledge__list-item-text">
                <p className="pledge__list-item-title">{itemTitle}</p>
                <DangerouslySetInnerHtml
                  className="pledge__list-item-description"
                  html={itemDescription}
                />
              </div>

              {image ? (
                <div className="pledge__list-item-image">
                  <UniversalImage img={image} imageAlt={imageAlt} />
                </div>
              ) : null}
            </li>
          )
        )}
      </ul>
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentsPledge on IPledge {
    sectionAnchor
    title
    description
    items {
      properties {
        title
        description
        image {
          ...FragmentFluidSmallImage
        }
        imageAlt
      }
    }
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
    sectionTheme
  }
`;

export default Pledge;
